import React from "react"
import { MobileOnlyCarousel } from "src/components/Carousel"
import Container from "src/components/Container"
import styled from "styled-components"
import { rem } from "src/utils/styling"
import TitleAndBody from "src/components/TitleAndBody"
import { screeningProcessData } from "src/data/apprenticeData"
import {
  DEVICE_TYPE_DESKTOP,
  DEVICE_TYPE_MOBILE,
  useDeviceType,
} from "src/utils/deviceType"

import TopArrowImg from "src/images/arrow/top-arrow.svg"
import MidArrowImg from "src/images/arrow/mid-arrow.svg"

const ProcessContainer = styled.div`
  margin: 1rem 0 6rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 550px) {
    margin: 1rem 0;
  }
  min-height: ${rem(200)}rem;
  margin-bottom: ${rem(200)}rem;
`

const ProcessContainerCardText = styled.div`
  font-size: ${rem(14)}rem;
  color: #000;
  line-height: ${rem(28)}rem;
  font-weight: 300;
  @media screen and (min-width: 1100px) {
    font-size: ${rem(17)}rem;
  }
  @media screen and (max-width: 550px) {
    line-height: ${rem(28)}rem;
  }
  padding: 0px ${rem(30)}rem;
  text-align: center;
  margin-top: ${rem(15)}rem;
`

const ProcessContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${rem(40)}rem ${rem(40)}rem;
  @media screen and (min-width: 1100px) {
    flex-direction: row;
  }
  @media screen and (max-width: 550px) {
    margin: ${rem(20)}rem 0 0;
  }
`

const ProcessContainerCardTitle = styled.div`
  color: #000;
  text-transform: uppercase;
  font-size: ${rem(15)}rem;
  background: linear-gradient(260.25deg, #e3e3e3 -10.66%, #e7e7e7 113%);
  height: fit-content;
  padding: ${rem(9)}rem ${rem(45)}rem;
  width: fit-content;
  text-align: center;
  border-radius: 80px;
  font-weight: 900;

  @media screen and (min-width: 1100px) {
    font-size: ${rem(15)}rem;
    margin: 0 auto;
  }

  @media screen and (max-width: 1100px) {
    margin: 0 auto;
  }
`

const ProcessContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  margin: 0 ${rem(10)}rem;
  padding: ${rem(30)}rem ${rem(20)}rem;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.01);
    border: 1px solid #ececec;
    box-sizing: border-box;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    ${ProcessContainerCardTitle} {
      color: #fff;
      transition: background 0.3s ease;
      background: ${props => props.bgColor};
    }
  }
  @media screen and (min-width: 1100px) {
    flex-direction: row;
    flex: 1;
  }
`

const ProcessContainerCardTextContainer = styled.div`
  margin: 0 auto;
  text-align: center;
`
const ProcessCardStep = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: ${rem(1100)}rem;
  margin: 0 auto;

  .topProcessContainer {
    margin-bottom: ${rem(100)}rem;
  }
`
const TopArrowImage = styled.img`
  width: ${rem(150)}rem;
  height: ${rem(150)}rem;
  position: absolute;
  top: -${rem(80)}rem;
  left: ${rem(300)}rem;
`
const MidArrowImage = styled.img`
  width: 50%;
  height: ${rem(200)}rem;
  position: absolute;
  top: ${rem(220)}rem;
  left: ${rem(300)}rem;
`

const ScreeningProcess = () => {
  const deviceType = useDeviceType()

  const topProcessData = screeningProcessData.slice(0, 3)
  const bottomProcessData = screeningProcessData.slice(3, 6)
  return (
    <Container>
      <ProcessContainer>
        <TitleAndBody title="Screening Process" />
        {deviceType === DEVICE_TYPE_DESKTOP ? (
          <ProcessCardStep>
            <TopArrowImage src={TopArrowImg} />
            <ProcessContainerContent className="topProcessContainer">
              {topProcessData.map(item => (
                <div style={{ flex: 1 }} key={item.title}>
                  <ProcessContainerCard bgColor={item.boxColor}>
                    <ProcessContainerCardTextContainer>
                      <ProcessContainerCardTitle>
                        {item.title}
                      </ProcessContainerCardTitle>
                      <ProcessContainerCardText>
                        {item.description}
                      </ProcessContainerCardText>
                    </ProcessContainerCardTextContainer>
                  </ProcessContainerCard>
                </div>
              ))}
            </ProcessContainerContent>
            <MidArrowImage src={MidArrowImg} />
            <ProcessContainerContent>
              {bottomProcessData.map(item => (
                <div style={{ flex: 1 }} key={item.title}>
                  <ProcessContainerCard bgColor={item.boxColor}>
                    <ProcessContainerCardTextContainer>
                      <ProcessContainerCardTitle>
                        {item.title}
                      </ProcessContainerCardTitle>
                      <ProcessContainerCardText>
                        {item.description}
                      </ProcessContainerCardText>
                    </ProcessContainerCardTextContainer>
                  </ProcessContainerCard>
                </div>
              ))}
            </ProcessContainerContent>
          </ProcessCardStep>
        ) : (
          <ProcessContainerContent>
            <MobileOnlyCarousel>
              {screeningProcessData.map(item => (
                <div style={{ flex: 1 }} key={item.title}>
                  <ProcessContainerCard bgColor={item.boxColor}>
                    <ProcessContainerCardTextContainer>
                      <ProcessContainerCardTitle>
                        {item.title}
                      </ProcessContainerCardTitle>
                      <ProcessContainerCardText>
                        {item.description}
                      </ProcessContainerCardText>
                    </ProcessContainerCardTextContainer>
                  </ProcessContainerCard>
                </div>
              ))}
            </MobileOnlyCarousel>
          </ProcessContainerContent>
        )}
      </ProcessContainer>
    </Container>
  )
}

export default ScreeningProcess
