import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { rem } from "src/utils/styling"
import AccurKardia from "src/images/logos/accur.svg"
import Canid from "src/images/logos/canid-logo.svg"
import Esports from "src/images/esports-logo.png"
import Ishi from "src/images/logos/ishi-logo.png"
import Sadanah from "src/images/logos/sadanah-logo.png"
import WYHF from "src/images/logos/WYHF-logo.svg"
import { useLocation } from "@reach/router"

const Container = styled.div`
  display: block;
  margin: ${rem(40)}rem auto ${rem(50)}rem;
  margin-bottom: ${rem(80)}rem;
  @media screen and (min-width: 1100px) {
    display: none;
  }
`

const Title = styled.p`
  font-size: ${rem(14)}rem;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: ${rem(2)}rem;
  margin: ${rem(20)}rem 0 ${rem(20)}rem;
  text-align: center;
`

const CompanyBlock = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  img {
    margin: ${rem(10)}rem 0;
    width: auto;
  }
  @media screen and (min-width: 1100px) {
    img {
      margin: ${rem(2)}rem ${rem(40)}rem;
    }
  }
`

const Img = styled.img`
  filter: grayscale(100%);
  opacity: 0.4;
  height: ${rem(20)}rem;
  @media screen and (min-width: 1100px) {
    height: ${rem(35)}rem;
  }
  ${props =>
    props.name === "esports" &&
    css`
      height: ${rem(45)}rem;
      @media screen and (min-width: 1100px) {
        height: ${rem(45)}rem;
      }
    `}
  ${props =>
    props.name === "sadanah" &&
    css`
      height: ${rem(65)}rem;
      @media screen and (min-width: 1100px) {
        height: ${rem(65)}rem;
      }
    `}
  ${props =>
    props.name === "wyhf" &&
    css`
      height: ${rem(55)}rem;
      @media screen and (min-width: 1100px) {
        height: ${rem(55)}rem;
      }
    `}
`

const MobileClients = () => {
  const [clientList, setClientList] = useState([
    { logo: Esports, company: "esports" },
    { logo: Canid, company: "canid" },
    { logo: Ishi, company: "ishi" },
  ])
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname.includes("social-enterprise")) {
      setClientList([
        { logo: WYHF, company: "wyhf" },
        { logo: Sadanah, company: "sadanah" },
      ])
    }
  }, [pathname])

  return (
    <Container>
      <Title>Select Clients</Title>
      <CompanyBlock>
        {clientList.map(client => (
          <Img
            key={client.company}
            src={client.logo}
            alt={`${client.company} logo`}
            name={client.company}
          />
        ))}
      </CompanyBlock>
    </Container>
  )
}

export default MobileClients
