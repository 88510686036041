import React from "react"
import styled from "styled-components"
import { Fade } from "react-slideshow-image"
import "react-slideshow-image/dist/styles.css"
import "../NavSlide.scss"
import { rem } from "src/utils/styling"

import SliderImage1 from "src/images/startups-slider-1.png"
import SliderImage1Mob from "src/images/enterprise-1-m.png"
import SliderImage3Mob from "src/images/startup-3-m.png"
import SliderImage3 from "src/images/slider-bg2.svg"
import SlideImage4 from "src/images/slide-user3.png"
import SliderImage2 from "src/images/apprentice_slide_2.png"
import SliderImage2Mob from "src/images/apprentice_slide_2_mob.png"

const Container = styled.div`
  position: relative;
  padding: ${rem(60)}rem 0 0;
  @media screen and (min-width: 1100px) {
    padding: ${rem(150)}rem 0 0;
  }

  .slide-container {
    margin: 0;
  }
`

const Slide = styled.div`
  width: 100%;
  text-align: center;
  height: ${rem(450)}rem;
  background-image: url(${SliderImage1});
  background-size: contain;
  display: flex;
  @media screen and (max-width: 1100px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-image: url(${SliderImage1Mob});
    background-size: cover;
    justify-content: center;
    align-item: center;
    border-radius: 0px;
    height: ${rem(570)}rem;
  }
`

const TextBox = styled.div`
  width: 60%;
  color: #fff;
  display: flex;
  padding: ${rem(120)}rem ${rem(120)}rem;
  flex-direction: column;
  @media screen and (max-width: 1100px) {
    width: 100%;
    text-align: center;
    padding: ${rem(40)}rem ${rem(40)}rem;
  }
`
const TextHeader = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: ${rem(45)}rem;
  line-height: 50px;
  color: #fff;
  max-width: fit-content;
  text-align: left;

  span {
    font-style: normal;
    color: #fcb240;
    margin-left: ${rem(5)}rem;
  }

  @media screen and (max-width: 1100px) {
    text-align: center;
    max-width: 100%;
    font-size: ${rem(28)}rem;
    line-height: ${rem(40)}rem;
  }
`
const SubTextHeader = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${rem(20)}rem;
  line-height: 30px;
  color: #ffffff;
  max-width: 70%;
  text-align: left;
  margin: ${rem(20)}rem 0 0;
  @media screen and (max-width: 1100px) {
    text-align: center;
    max-width: 100%;
    font-size: ${rem(16)}rem;
  }
`

const SlideTwo = styled(Slide)`
  background-image: url(${SliderImage2});
  background-size: cover;
  @media screen and (max-width: 1100px) {
    background-image: url(${SliderImage2Mob});
    background-size: cover;
    background-repeat: no-repeat;
  }
`

const SlideThree = styled(Slide)`
  background-image: url(${SliderImage3});
  background-size: cover;
  @media screen and (max-width: 1100px) {
    background-image: url(${SliderImage3Mob});
    background-size: cover;
    background-repeat: no-repeat;
  }
`

const SlideFour = styled(Slide)`
  width: 100%;
  text-align: center;
  height: ${rem(450)}rem;
  background: #03162c;
  display: flex;
  position: relative;
  @media screen and (max-width: 1100px) {
    width: 100%;
    background: #03162c;
    display: inline-block;
    height: ${rem(570)}rem;
  }
`
const UserBox = styled.div`
  width: 50%;
  color: #fff;
  display: flex;
  padding: ${rem(150)}rem ${rem(120)}rem;
  position: relative;
  @media screen and (max-width: 1100px) {
    width: 100%;
    padding: ${rem(130)}rem 0 0;
    text-align: center;
    display: inline-block;
    margin: ${rem(20)}rem 0 0;
    position: absolute;
    left: 0;
    bottom: -2rem;
  }
`
const ImageBox = styled.img`
  transform: scale(2.7);
  position: relative;
  bottom: ${rem(-50)}rem;
  height: 120px;
  width: auto;
  @media screen and (max-width: 1100px) {
    position: relative;
    top: ${rem(-90)}rem;
    width: 100px;
    height: auto;
    right: ${rem(20)}rem;
  }
`
const UserTextBox = styled.div`
  width: 100%;
  color: #fff;
  display: flex;
  position: relative;
  left: -75px;
  padding: ${rem(40)}rem ${rem(0)}rem;
  flex-direction: column;
  @media screen and (max-width: 1100px) {
    position: absolute;
    top: 0;
    left: 70px;
    padding: 0;
  }
`
const UserNameText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: ${rem(25)}rem;
  line-height: 30px;
  color: #fff;
  @media screen and (max-width: 1100px) {
    text-align: center;
  }
`

const FirstNameText = styled.span`
  color: #000;
`
const CountryText = styled.span`
  font-weight: 400;
  font-size: ${rem(17)}rem;
  margin-left: ${rem(65)}rem;
`

const StartupSlide = () => {
  return (
    <Container>
      <div className="slide-container">
        <Fade indicators={true} arrows={false} autoplay={true}>
          <div className="each-fade">
            <Slide>
              <TextBox>
                <TextHeader>Unlock Global Talent</TextHeader>
                <SubTextHeader>
                  Remote work has provided access to global talent like never
                  before
                </SubTextHeader>
              </TextBox>
            </Slide>
          </div>
          <div className="each-fade">
            <SlideTwo>
              <TextBox>
                <TextHeader>Traditional Recruiting Fails</TextHeader>
                <SubTextHeader>
                  However traditional recruiting is not the best way to identify
                  or hire remote talent
                </SubTextHeader>
              </TextBox>
            </SlideTwo>
          </div>
          <div className="each-fade">
            <SlideThree>
              <TextBox>
                <TextHeader>Auditions Over Interviews</TextHeader>
                <SubTextHeader>
                  Critically it is important to audition talent securely and
                  respectfully before you hire
                </SubTextHeader>
              </TextBox>
            </SlideThree>
          </div>
          <div className="each-fade">
            <SlideFour>
              <TextBox>
                <TextHeader>Introducing Our Apprenticeship Program</TextHeader>
                <SubTextHeader>
                  Shortlist Candidates | Execute Audition | Make a Full-time
                  Offer
                </SubTextHeader>
              </TextBox>
              <UserBox>
                <ImageBox src={SlideImage4} />
                <UserTextBox>
                  <UserNameText>
                    James Midzi
                    <br />
                    <CountryText>Harare</CountryText>
                  </UserNameText>
                </UserTextBox>
              </UserBox>
            </SlideFour>
          </div>
        </Fade>
      </div>
      <br />
      <br />
    </Container>
  )
}

export default StartupSlide
